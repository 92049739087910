<template>
  <el-dialog :close-on-click-modal="false" title="用户添加" :visible="dialogVisible" width="50%" @close="dialogClosed">
    <el-form :model="info" :rules="rule" ref="singleForm" label-width="100px">
      <el-form-item label="用户名:" prop="username">
        <el-input v-model.trim="info.username" size="small"></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="password">
        <el-input v-model.trim="info.password" size="small"></el-input>
      </el-form-item>
      <el-form-item label="apiKey:" prop="apiKey">
        <el-input v-model.trim="info.apiKey" size="small"></el-input>
      </el-form-item>
      <el-form-item label="开始时间:" prop="from">
        <el-date-picker :clearable="false" size="small" v-model="info.from" type="date"
          placeholder="选择开始时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间:" prop="to">
        <el-date-picker :clearable="false" size="small" v-model="info.to" type="date"
          placeholder="选择结束时间"></el-date-picker>
      </el-form-item>

      <el-form-item label="电商平台" prop="shopType">
        <el-select v-model="info.shopType" placeholder="请选择电商平台">
          <el-option v-for="item in optionImg" :key="item.url" :label="item.label" :value="item.value">
            <img :src="item.url" style="height: 28px;">
            <span style="font-weight: bold;">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="用户类型" prop="shopType">
        <el-select v-model="info.roleType" placeholder="请选择用户类型">
          <el-option v-for="item in roleTypes" :key="item.label" :label="item.label" :value="item.value">
            <span style="font-weight: bold;">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="店铺管理员" prop="parentId">
        <el-input v-model.trim="info.parentId" size="small" style="width: 187px;"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">返 回</el-button>
      <el-button size="small" :loading="btnLoading" type="primary" @click="save">保 存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { mapActions } from 'vuex'
export default {
  name: 'user-single',
  data() {
    return {
      btnLoading: false,
      title: '新建用户',
      isEdit: false,
      dialogVisible: false,
      info: {
        username: '',
        password: '',
        apiKey: '',
        from: '',
        to: '',
        shopType: '',
        roleType: '',
        parentId: 0
      },
      optionImg: [
        {
          label: '全部',
          value: 'all',
          url: ''
        },
        {
          label: 'worten',
          value: 'worten',
          url: require('../../assets/logo-worten.png')
        },
        {
          label: 'fnac',
          value: 'fnac',
          url: require('../../assets/logo-fnac.png')
        },
        {
          label: 'catch',
          value: 'catch',
          url: require('../../assets/logo-catch.png')
        },
        {
          label: 'eprice',
          value: 'eprice',
          url: require('../../assets/logo-eprice.png')
        },
        {
          label: 'fyndiq',
          value: 'fyndiq',
          url: require('../../assets/logo-fyndiq.png')
        },
        {
          label: 'onbuy',
          value: 'onbuy',
          url: require('../../assets/logo-onbuy.png')
        },
        {
          label: 'rdc',
          value: 'rdc',
          url: require('../../assets/logo-rdc.png')
        },
      ],

      roleTypes: [
        {
          label: '用户',
          value: 2
        },
        {
          label: '店铺管理员',
          value: 3
        }
      ],

      rule: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        apiKey: [
          { required: true, message: '请输入apiKey', trigger: 'blur' }
        ],
        from: [
          { required: true, type: 'date', message: '请选择开始时间', trigger: 'change' }
        ],
        to: [
          { required: true, type: 'date', message: '请选择结束时间', trigger: 'change' }
        ],
        shopType: [
        { required: true, message: '请选择电商平台', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['addUser', 'editUser']),
    open(obj = {}) {
      this.info = {
        username: '',
        password: '',
        apiKey: '',
        from: '',
        to: '',
        shopType: '',
        parentId: 0,
        roleType: 2,
      }
      if (Object.keys(obj).length) {
        this.isEdit = true
        this.title = '修改用户'
        this.info.id = obj.id
        Object.keys(this.info).forEach(key => {
          this.info[key] = obj[key] || ''
        })
        this.info.roleType = obj.role

        this.info.from = new Date(this.info.from * 1000)
        this.info.to = new Date((this.info.to + 1 - 60 * 60 * 24) * 1000)
      } else {
        this.title = '新增用户'
        this.isEdit = false
      }
      this.dialogVisible = true
    },
    dialogClosed() {
      this.dialogVisible = false
      this.$refs.singleForm.resetFields()
    },
    save() {
      this.$refs.singleForm.validate(async valid => {
        if (!valid) return
        if (this.info.from > this.info.to) {
          return this.$message.error('结束时间不应小于开始时间')
        }
        let params = cloneDeep(this.info)
        params.from = params.from.valueOf() / 1000
        params.to = params.to.valueOf() / 1000 + 60 * 60 * 24 - 1
        try {
          this.btnLoading = true
          if (this.isEdit) {
            await this.editUser(params)
          } else {
            await this.addUser(params)
          }
          this.$message.success(`${this.title}成功！`)
          this.$emit('update')
          this.dialogVisible = false
        } catch (e) {
          this.$message.error(e)
        } finally {
          this.btnLoading = false
        }
      })
    }
  }
}
</script>